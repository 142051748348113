import { Controller } from "@hotwired/stimulus"

import Highcharts from 'highcharts/highstock'

import { fetchScoreable, fetchScores } from '../mixins/chart'

export default class extends Controller {
  static values = {
    slug: String,
    scoreableUrl: String,
    scoresUrl: String
  }

  initialize = async _ => {
    const scoreable = await fetchScoreable(this.scoreableUrlValue)
    const scores = await fetchScores(this.scoresUrlValue)

    this.renderChart(scoreable, scores)
  }

  connect = _ => {
  }

  renderChart = (scoreable, scores) => {
    let series = [], values = [], volumes = []

    scores.map((score) => {
      const timestamp = new Date(score.scored_on).getTime()
      values.push([timestamp, score.value_rounded])
      volumes.push([timestamp, score.volume])
    })

    series.push(
      {
        id: scoreable.id,
        name: `Score`,
        color: scoreable.display_colour,
        data: values,
        dataGrouping: {
          enabled: false
        }
      }, {
        id: `${scoreable.id}_volume`,
        type: 'column',
        name: `Volume`,
        color: scoreable.display_colour,
        data: volumes,
        yAxis: 1
      }
    )

    Highcharts.stockChart(`${scoreable.slug}_chart_container`, {
      chart: {
        id: 'stock-chart',
        backgroundColor: 'transparent'
      },
      boost: {
          useGPUTranslations: true,
          // Chart-level boost when there are more than 5 series in the chart
          seriesThreshold: 0
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      yAxis: [
        {
          labels: {
            align: 'left'
          },
          height: '80%',
          resize: {
            enabled: true
          }
        },
        {
          labels: {
            align: 'left'
          },
          top: '80%',
          height: '20%',
          offset: 0
        }
      ],
      credits: {
        enabled: false
      },
      series: series
    })
  }
}
