import { Controller } from '@hotwired/stimulus'

import Highcharts from 'highcharts'

import { fetchScoreable, fetchScores } from '../mixins/chart'

export default class extends Controller {
  static values = {
    slug: String,
    scoreableUrl: String,
    scoresUrl: String
  }

  initialize = async _ => {
    const scoreable = await fetchScoreable(this.scoreableUrlValue)
    const scores = await fetchScores(this.scoresUrlValue)

    this.renderChart(scoreable, scores)
  }

  connect = _ => {
  }

  renderChart = (scoreable, scores) => {
    let values = []

    scores.map((score) => {
      const timestamp = new Date(score.scored_on).getTime()
      values.push([timestamp, score.value_rounded])
    })

    const series = [
      {
        id: scoreable.id,
        name: `Score`,
        color: scoreable.display_colour,
        data: values,
        dataGrouping: {
          enabled: false
        }
      }
    ]

    Highcharts.chart(`${scoreable.slug}_chart_container`, {
      chart: {
        type: 'spline',
        id: 'stock-chart',
        backgroundColor: 'transparent',
        height: '200'
      },
      boost: {
          useGPUTranslations: true,
          // Chart-level boost when there are more than 5 series in the chart
          seriesThreshold: 0
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        lineWidth: 0,
        tickWidth: 0
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        title: {
          enabled: false
        }
      },
      credits: {
        enabled: false
      },
      series: series
    })
  }
}
