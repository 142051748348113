export const fetchScoreable = async url => {
  const scoreable = await fetch(url)
                            .then(response => response.json())
                            .then(json => json)

  return scoreable
}

export const fetchScores = async url => {
  const scores = await fetch(url)
                         .then(response => response.json())
                         .then(json => json)

  return scores
}
